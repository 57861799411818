@import '_variables';
@import '_mixins';

body {
    main {
        @include contentWidth;

        .page-title-wrapper {
            .page-title {
                text-align: center;
                a {
                    color: $white;
                    &:hover {
                        color: $white;
                        //text-decoration: none;
                    }
                }
                .parent {
                    text-transform: uppercase;
                    font-size: .5em;
                    letter-spacing: 2px;

                }
                h1 {
                    margin: 0;
                    font-size: 2em;
                    font-weight: normal;
                    line-height: 1.2em;
                }
                h1::first-letter {
                    text-transform: capitalize;
                }
            }
            .strapline {
                margin: 2em 20% 0;
                text-align: center;
                font-size: 1.2em;
                line-height: 1.4em;
                font-style: italic;
            }
            .author {
                margin-top: 3em;
                text-align: center;
                text-transform: uppercase;
                font-size: .5em;
                letter-spacing: 2px;
                color: $almostWhite;
                a {
                    color: $almostWhite;
                }
            }
        }

        .quote-wrapper {
            margin: 3em 0;

            .text {
                position: relative;
                text-align: center;
                font-style: italic;
            }
            .author {
                margin: 1em 1em 0;
                text-align: right;
                font-size: .7em;
                color: #aaa;
                a {
                    color: #aaa;
                }
            }
        }

        .teaser-wrapper {
            margin: 2em 0;
            .teaser {
                text-align: center;
                font-style: italic;
            }
        }

        .content-wrapper {

            .children-wrapper {
                @include fullBleed;
                background: #f0f0f0 url(https://lh3.googleusercontent.com/selebGBhDJ0zLxPFSP3PNJCXQ9pMxrrHSJuHCOkvdWksvKtrtwowTdMCjbS9-zFe_i2AyTmpos6ulN_itj0=w1500-no-tmp.jpg) no-repeat center center;
                background-size: cover;
                background-attachment: fixed;
                h2.section-title {
                    margin-top: .2em;
                }
                .children {
                    @include contentWidth;
                    padding: 1em 0 1.5em;

                    .list {
                        display: flex;
                        justify-content: space-between;
                        -webkit-justify-content: space-between;
                        flex-wrap: wrap;
                        -webkit-flex-wrap: wrap;
                        .child {
                            width: 45%;
                            display: flex;
                            margin-top: 1em;
                            .image {
                                width: 100px;
                                flex-shrink: 0;
                                -webkit-flex-shrink: 0;
                                margin: .3em 1em 0 0;
                                img {
                                    width: 100px;
                                    height: 70px;
                                    @include box_shadow;
                                }
                            }
                            .text {
                                .name {
                                    font-size: 1.1em;
                                }
                                .strapline {
                                    height: 2.8em;
                                    overflow: hidden;
                                    font-size: .7em;
                                    line-height: 1.4em;
                                    color: #999;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }

            article {
                margin: 3em 0;

                h1, h2, h3 {
                    line-height: 1.4em;
                    em {
                        position: absolute;
                        width: 3em;
                        margin-left: -3.4em;
                        text-align: right;
                        color: #eee;
                    }
                }
                ul {
                    margin-top: 1em;
                    margin-bottom: 2em;
                    padding-left: 40px;
                    ul {
                        margin: 0;
                    }
                }
                .small {
                    font-size: .8em;
                    line-height: 1.5em;
                }

                .w50-right {
                    float: right;
                    width: 50%;
                    p {
                        margin: 0;
                    }
                }

                .flex {
                    display: flex;
                    p {
                        flex-grow: 1;
                    }
                }

                .gallery {
                    margin: 0;
                    p {
                        margin: .5em 0;
                        display: flex;
                        justify-content: space-between;
                        -webkit-justify-content: space-between;
                    }
                }
                img {
                    &:not(.no-shadow) {
                        @include box_shadow;
                    }
                    &:not(.original-size) {
                        width: 100%;
                    }
                    &.original-size {
                        max-width: 100%;
                    }
                    &.center {
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                    }
                    &.w66 {
                        width: 65%;
                        height: 65%;
                    }
                    &.w50 {
                        width: 49.5%;
                        height: 49.5%;
                    }
                    &.w33 {
                        width: 32.5%;
                        height: 32.5% ;
                    }
                }
                // https://css-tricks.com/scaled-proportional-blocks-with-css-and-javascript/
                .h169 {
                    @include image;
                    padding-bottom: 56.2%;
                }
                .h400 {
                    @include image;
                    padding-bottom: 40%;
                }
                .w66 {
                    @include image;
                    width: 65%;
                }
                .w50 {
                    @include image;
                    width: 49.5%;
                    // h:350 / w:500 * 49.5%
                    padding-bottom: 34.65%;
                }
                .w50h {
                    @include image;
                    width: 49.5%;
                    // h:600 / w:500 * 49.5%
                    padding-bottom: 59.4%;
                }
                .w33 {
                    @include image;
                    width: 32.5%;
                    // h:250 / w:350 * 32.5%
                    padding-bottom: 23.21%;
                }
                .w33h {
                    @include image;
                    width: 32.5%;
                    // h:500 / w:350 * 32.5%
                    padding-bottom: 46.43%;
                }

                figcaption:not(:empty) {
                    margin-top: -1.2em;
                    padding-left: 1.2em;
                    font-size: .65em;
                    line-height: 1.4em;
                    color: $grey;
                    position: relative;
                    a {
                        color: $darkGrey;
                    }
                    &::before {
                        content:"";
                        position: absolute;
                        left: .05em;
                        top: .2em;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 12px solid $link;
                    }
                }

                .message {
                    margin: 1em auto;
                    @include fullBleed;
                    padding: .1em 0;
                    & > div {
                        @include contentWidth;
                    }
                    &.note {
                        background: $lightYellow url(../../public/img/icons/warning.svg) no-repeat calc(50% - 490px) 28px;
                        background-size: 32px 32px;
                        color: #840;
                    }
                    &.error {
                        background: #fcf4f2 url(../../public/img/icons/error.svg) no-repeat calc(50% - 490px) 28px;
                        background-size: 32px 32px;
                        color: #a51b00;
                    }
                    &.info {
                        background: #f1f1f1 url(../../public/img/colorbox/info.svg) no-repeat calc(50% - 490px) 28px;
                        background-size: 32px 32px;
                    }
                }
                .sticky {
                    margin-top: -3em !important;
                    margin-bottom: 2em;
                }
                .explain {
                    margin: 1em 0;
                    background-color: #f1f1f1;
                    border-top: 1px solid $link;
                    .title {
                        padding: .5em .8em;
                        &:hover {
                            cursor: pointer;
                            text-decoration: underline;
                            background-color: #e9e9e9;
                        }
                        p {
                            margin: 0;
                        }
                    }
                    .body {
                        padding: 0 .8em .1em;
                        border-top: 2px solid $white;
                        &:not(.expanded) {
                            display: none;
                        }
                    }
                }
                .aside {
                    position: relative;
                    width: calc((100vw - 900px) / 2 - 2em);
                    min-width: 10em;
                    background-color: #f9f9f9;
                    border-top: 1px solid $link;
                    font-size: .9em;
                    line-height: 1.4em;
                    &.aside-left {
                        float: left;
                        margin-left: calc(50% - 50vw + 1em);
                        margin-right: 1em;
                    }
                    &.aside-right {
                        float: right;
                        margin-right: calc(50% - 50vw + 1em);
                        margin-left: 1em;
                    }
                    &:hover {
                        background-color: #f1f1f1;
                    }
                    &.related {
                        a {
                            display: block;
                            padding: .5em;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }
                    &.toc {
                        background-color: $veryLightYellow;
                        border-top: 1px solid $link;
                        padding: .5em;
                        font-size: .8em;
                        ul {
                            margin: 0;
                            padding-left: 15px;
                        }
                    }
                    p {
                        margin: 0;
                    }
                    a {
                        strong {
                            color: $black;
                        }
                    }
                }
                em {
                    color: $drupalDarkBlue;
                    color: #840;
                }
                a {
                    em {
                        color: $link;
                    }
                }
                hr {
                    height: 1px;
                    border: none;
                    color: $darkLink;
                    background-color: $darkLink;
                }

                .source {
                    margin-top: -1.5em;
                    text-align: right;
                    font-size: .65em;
                    line-height: 1.4em;
                    color: $grey;
                    a {
                        color: $grey;
                    }
                }

                .embed-wrapper {
                    position: relative;
                    width: 100%;
                    height: 0;
                    .embed, .issuuembed {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    &.issuu {
                        padding-bottom: 78%;
                    }
                    &.youtube {
                        padding-bottom: 56%;
                    }
                    &.strava {
                        text-align: center;
                        padding-bottom: 405px;
                        iframe {
                            max-width: 100%;
                        }
                    }
                    &.komoot {
                        text-align: center;
                        padding: 0 100px 600px;
                        iframe {
                            max-width: 100%;
                        }
                    }
                }

                .event-calendar {
                    table {
                        width: 100%;
                        border-top: 1px dashed #ddd;
                        thead {
                            display: none;
                        }
                        td {
                            padding: .5em;
                            border-bottom: 1px dashed #ddd;
                        }
                    }
                    &:not(.year) {
                        td:not(:nth-child(1)) {
                            font-size: .8em;
                            line-height: 1.4em;
                        }
                    }
                    &.year {
                        td:not(:nth-child(2)) {
                            font-size: .8em;
                            line-height: 1.4em;
                        }
                    }
                }

                .route-profile {
                    margin: -2.5em 0 -1em;
                }

                .content-footer {
                    margin-top: 4em;
                    border-top: 2px solid #e7e9ee;
                    padding-top: .5em;

                    display: flex;
                    justify-content: space-between;
                    -webkit-justify-content: space-between;

                    font-size: .75em;
                    line-height: 1.4em;
                    color: #656c7a;
                    .title {
                        font-size: .9em;
                    }
                    .highlight {
                        font-size: 1.2em;
                        margin: .2em 0;
                        &:first-letter {
                            text-transform: capitalize;
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        li {
                            display: inline-block;
                        }
                        li:after {
                            content: ' /';
                            color: $lightGrey;
                        }
                        li:last-of-type:after {
                            content: '';
                        }
                    }
                    .date {
                        margin-left: 20%;
                        text-align: right;
                        white-space: nowrap;
                        font-size: .9em;
                    }
                }

                .prev-next-wrapper {
                    margin: 3em 0;
                    @include fullBleed;
                    padding: 1em 0 .5em;
                    background-color: #f0f0f0;
                    &.with-related {
                        margin-bottom: -2.9em;
                    }
                    .prev-next {
                        @include contentWidth;
                        display: flex;
                        justify-content: space-between;
                        -webkit-justify-content: space-between;
                        .post {
                            width: 45%;
                            &.next {
                                text-align: right;
                            }
                            .arrow {
                                display: block;
                                font-size: 1.2em;
                                color: $grey;
                            }
                            a:hover .arrow {
                                text-decoration: none;
                            }
                        }
                    }
                }

                .related-wrapper {
                    margin: 3em 0;
                    @include fullBleed;
                    padding: 1em 0 .5em;
                    background-color: #f0f0f0;

                    .related {
                        @include contentWidth;
                        font-size: .9em;
                        line-height: 1.2em;
                        h2 {
                            margin: 0 0 .5em;
                            color: #656c7a;
                        }
                        .list {
                            .post {
                                margin-top: .5em;
                                .title {
                                    .parent {
                                        color: $black;
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .comments-wrapper {
                @include fullBleed;
                padding: 1em 0;
                border-top: 2px solid #f0f0f0;
                background-color: #fbfbfb;
                .comments {
                    @include contentWidth;
                    #show-comments {
                        display: block;
                        text-align: center;
                        font-size: .8em;
                        color: $grey;
                    }
                }
            }

        }

        .main-footer-wrapper {
            @include fullBleed;
            //margin-top: 3em;
            padding: .3em;
            background-color: #f0f0f0;
            border-top: 2px solid #e7e9ee;
            font-size: .8em;
            .main-footer {
                @include contentWidth;
                display: flex;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                line-height: 1.2em;
                a {
                    color: $black;
                }
                .breadcrumbs {
                    margin-right: 1em;
                    a:after {
                        content: '';
                        display: inline-block;
                        margin: 0 .2em 0 .5em;
                        width: 0;
                        height: 0;
                        border-top: 0.3rem solid transparent;
                        border-bottom: 0.3rem solid transparent;
                        border-left: 0.5rem solid $almostBlack;
                    }
                    a:last-of-type:after {
                        border: none;
                    }

                }
                .back-to-top {
                    white-space: nowrap;
                }
            }
        }
    }
}

body.hero {
    main {
        .page-title-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 5;
            display: flex;
            flex-direction: column;
            -webkit-flex-direction: column;
            justify-content: center;
            -webkit-justify-content: center;
            padding-top: 2em;
            .page-title {
                width: 100%;
                display: flex;
                flex-direction: column;
                -webkit-flex-direction: column;
                justify-content: flex-end;
                -webkit-justify-content: flex-end;
                align-items: center;
                -webkit-align-items: center;
                h1 {
                    width: 100%;
                    margin: 0;
                    padding: 0 10%;
                    font-size: 4em;
                    font-weight: normal;
                    line-height: 1.1em;
                    color: $white;
                }
                .parent {
                    margin-bottom: 2em ;
                }
            }
            .strapline {
                color: $white;
            }
        }
        .content-wrapper {
            margin-top: 99vh;
            padding-top: 1vh;
        }
    }
}
body:not(.hero) {
    main {
        .page-title-wrapper {
            @include fullBleed;
            background-color: $almostBlack;
            @include backgroundNoise;
            color: $white;
            .page-title {
                padding: 5em 0 2em;
                @include contentWidth;
            }
        }
        .teaser-wrapper {
            margin: 0;
            @include contentWidth;
            padding-bottom: 2em;
        }
    }
}

body.template-list {
    main {
        .content-wrapper {
            .posts-wrapper {
                .posts {
                    .list {
                        .post {
                            .text {
                                .parent {
                                    font-size: .6em;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;
                                }
                                .title {
                                    font-size: 1.1em;
                                }
                                .strapline {
                                    margin-top: 1em;
                                    height: 4.5em;
                                    overflow: hidden;
                                    font-size: .7em;
                                    line-height: 1.5em;
                                    color: $grey;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
            .posts-list-wrapper {
                .posts {
                    .list {
                        margin: 3em 0;
                        .post {
                            display: flex;
                            margin-top: 2em;
                            .image {
                                margin-right: 2em;
                                img {
                                    @include box_shadow;
                                }
                            }
                            .text {
                                .parent {
                                    font-size: .6em;
                                    color: $grey;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;
                                    a {
                                        color: $grey;
                                    }
                                }
                                .title {
                                    font-size: 1.1em;
                                }
                                .strapline {
                                    margin-top: 1em;
                                    height: 4.5em;
                                    overflow: hidden;
                                    font-size: .7em;
                                    line-height: 1.5em;
                                    color: $grey;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 3;
                                    -webkit-box-orient: vertical;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.template-simple {
    main {
        .content-wrapper {
            .posts-wrapper {
                .posts {
                    .post {
                        margin-bottom: .8em;
                        padding-bottom: .8em;
                        border-bottom: 1px solid #eee;
                        .title {
                            font-weight: bold;
                            .parent {
                                color: $black;
                            }
                        }
                        .strapline {
                            margin-top: .2em;
                        }
                    }
                }
            }
        }
    }
}

body.section-development, body.section-blog, body.tag-projects {
    main {
        .content-wrapper {
            .posts-wrapper {
                .posts {
                    .list {
                        .post {
                            .text {
                                .strapline {
                                    height: auto;
                                    margin-top: .5em;
                                }
                            }
                        }
                    }
                }
            }
            article {
                .related-wrapper {
                    .related {
                        .list {
                            .post {
                                .image {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.section-travel, body.section-sport {
    main {
        .content-wrapper {
            article {
                .related-wrapper {
                    .related {
                        font-size: .8em;
                        line-height: 1.2em;
                        h2 {
                            margin: 0;
                        }
                        .list {
                            display: flex;
                            justify-content: space-between;
                            -webkit-justify-content: space-between;
                            flex-wrap: wrap;
                            -webkit-flex-wrap: wrap;
                            margin-top: .5em;
                            .post {
                                width: 31%;
                                .image {
                                    @include image;
                                    padding-bottom: 66.67%;
                                    margin-top: .5em;
                                }
                                .title {
                                    margin-top: .4em;
                                    .parent {
                                        color: $black;
                                        font-weight: bold;
                                    }
                                }
                                a {
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.dark {
    background-color: $almostBlack;
}

body.error {
    main {
        .page-title-wrapper {
            .page-title {
                a {
                    font-size: .9em;
                    color: $lightGrey;
                }
            }
        }
    }
}
